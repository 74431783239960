// import "../HowLong/HowLong.css";
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import back from '../../assest/left-arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm } from '../../Store/Action/formActions';

function When_Do({data, setFormData, laststep,  setLastStep, changeStep}) {
  console.log(data);
  const [activeButton, setActiveButton] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = (whenButton) => {
    setActiveButton(whenButton);
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      when: whenButton, // Update the name field
    }));

    
    if(whenButton === 'I HAVE SPECIFIC DATES'){
      setLastStep(4);
      changeStep(5);
    }
    else if(whenButton === "I DON'T MIND (I'M FLEXIBLE)" ){
      setLastStep(4);
      changeStep(7);
    }
    else{
      setLastStep(4);
      changeStep(6);
    }
  };
  const clickPrev = (step) => {
   
    
    if(data.main =='Indian Jungles')
    { console.log(step)
      setLastStep(step-1);
      changeStep(step);
      console.log("hello")
     
    }else{
      console.log(step)
      setLastStep(step-1);
      console.log(step);
      changeStep(step);
      console.log("welcome")
    
    }
  }
  return (
    <div className="container">
        <h1 className='heading'>When do you Want to travel?</h1>
        <div className='div-hbut'>
        <Link 
          className={`hbut1 ${data.when === 'I HAVE SPECIFIC DATES' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('I HAVE SPECIFIC DATES')}
        >
          I HAVE SPECIFIC DATES
        </Link>
        <Link 
          className={`hbut1 ${data.when === '2024' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('2024')}
        >
          2024
        </Link>
        <Link 
          className={`hbut1 ${data.when === '2025' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('2025')}
        >
         2025
        </Link>
        <Link 
          className={`hbut1 ${data.when === '2026' ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick('2026')}
        >
         2026
        </Link>
        <Link 
          className={`hbut1 ${data.when === "I DON'T MIND (I'M FLEXIBLE)" ? 'active' : ''}`} 
          to="#"
          onClick={() => handleButtonClick("I DON'T MIND (I'M FLEXIBLE)")}
        >
         I DON'T MIND (I'M FLEXIBLE)
        </Link>
        <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
      </div>
      <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `35%` }}
              ></div>
            </div>
          </div>


{/* 
        <button className='p-btn place-btn'>I have specific dates</button>
        <button className='p-btn place-btn'>2024</button>
        <button className='p-btn place-btn'>2025</button>
        <button className='p-btn place-btn'>2026</button>
        <button className='p-btn place-btn'>I don't mind (I'm flexible)</button> */}
    </div>
  )
}

export default When_Do