import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Main = ({ data = {}, setFormData, changeStep }) => {
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    console.log(activeButton); // This logs the active button whenever it's changed
  }, [activeButton]);

  const clickNext = (buttonLabel, step) => {
    setActiveButton(buttonLabel);
    setFormData((prevState) => ({
      ...prevState,
      main: buttonLabel, // Update the "main" field in the form data
    }));
    changeStep(step); // Change the step when a button is clicked
  };

  return (
    <div className="main-container">
      <div className="container">
        <h1 className="heading" style={{ fontSize: '2.9em' }}>Got 2min? Let’s plan your travel (game drive).</h1>
        <h2 className="heading" >Which Drive would you like?</h2>

        <div className="div-hbut">
          <Link
            to="#"
            className={`hbut1 ${data.main === 'Indian Jungles' ? 'active' : ''}`}
            onClick={() => clickNext('Indian Jungles', 3)}
          >
            Indian Jungles
          </Link>
          <Link
            to="#"
            className={`hbut1 ${data.main === 'African Savanas' ? 'active' : ''}`}
            onClick={() => clickNext('African Savanas', 2)}
          >
            African Savanas
          </Link>
        </div>
      </div>

      <div style={{ padding: '20px' }}>
        <span className='pro-heading'>Progress</span>
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `0%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default Main;
