import React, { useState } from 'react';
import '../src/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Budget from './components/Budget/KenyaBudget';
import IndiaBudget from './components/Budget/IndiaBudget';
import When_Do from './components/When_Do/When_Do';
import SelectDate from './components/SelectDate/SelectDate';
import HowLong from './components/HowLong/HowLong';
import WhichMonth from './components/WhichMonth/WhichMonth';
import TravelWith from './components/TravelWith/TravelWith';
import Main from './components/Main/Main';
import Message from './components/Message/Message';
import UserForm from './components/UserForm/Form';
import WhichDrive from './components/which_drive';

// import SummaryPage from './containers/SummaryPage';
// import SafariTravel from './components/safari_and_tranfer/SafariTravel';

// import SelectDate from 

const App = () => {
  const [step, setStep] = useState(1);
  const [prevStep, setPrevStep] = useState(1);

  const [formData, setFormData] = useState({
    main: '',
    kenyabudget:'',
    indiabudget: '',
    when: '',
    startDate:[],
    endDate: [],
    Selectmonth: '',
    duration: '',
    travelwith: [],
    // safari:'',
   
    message: '',
  
  });

  const setLastStep = (step) => setPrevStep(step);

  const changeStep = (step) => setStep(step);

  const renderForm = () => {

    switch(step)
    {
      case 1:
        return(<Main data={formData} setFormData={setFormData} changeStep={changeStep} /> );
      // case 2:
      //   return(<WhichDrive data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
      //   );
      case 2:
        return(
          <Budget data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
        );
        case 3:
          return(
            <IndiaBudget data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep}  />
          );
        case 4:
          return(
            <When_Do data={formData}  setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
          );
          case 5:
        return(
          <SelectDate data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
        );
        case 6:
          return(
            <WhichMonth data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
          );
          case 7:
            return(
              <HowLong data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
            );
          case 8:
              return(
                <TravelWith data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
              );
              // case 9:
              //   return(
              //     <SafariTravel data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
              //   );
              case 9:
                return(
                  <Message data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
                );
                case 10:
                  return(
                    <UserForm data={formData} setFormData={setFormData} laststep={prevStep} setLastStep={setLastStep} changeStep={changeStep} />
                  );
          

          default:
            return(<Main changeStep={changeStep} /> );
    }
  }
  
  return (
    <>
      <Router>
        {renderForm()}
      </Router>
    </>
  )
}

export default App