// import "../HowLong/HowLong.css";
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import back from '../../assest/left-arrow.png';


function Budget({data, setFormData, laststep,  setLastStep, changeStep}) {
  const [activeButton, setActiveButton] = useState(null);


  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      kenyabudget: buttonLabel, // Update the name field
    }));
 
    setLastStep(2);
    changeStep(4);
  };

  const clickPrev = (step) => {
    setLastStep(step-1);
    changeStep(step);
}

  return (
    <div className="container">
        <h1 className='heading'>What is your budget per person?</h1>
        <div className='div-hbut'>
          <Link 
            className={`hbut1 ${data.budget=== '$3000 - $4000 ' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('$3000 - $4000 ')}
          >
            $3000 - $4000 
          </Link>
          <Link 
            className={`hbut1 ${data.budget === '$4000 - $5000' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('$4000 - $5000')}
          >
            $4000 - $5000
          </Link>
          <Link 
            className={`hbut1 ${data.budget === '$5000+' ? 'active' : ''}`} 
            to="#"
            onClick={() => handleButtonClick('$5000+')}
          >
            $5000+
          </Link>
          
        </div>


        
        <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
        <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `20%` }}
              ></div>
            </div>
          </div>
    </div>
  )
}

export default Budget