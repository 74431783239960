import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import back from '../../assest/left-arrow.png';

function IndiaBudget({ data = {}, setFormData, laststep, setLastStep, changeStep }) {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
    setFormData((prevState) => ({
      ...prevState,
      indiabudget: buttonLabel,
    }));
 
    setLastStep(3);
    changeStep(4);
  };

  const clickPrev = (step) => {
      setLastStep(step - 2);
      changeStep(step - 2);
  };

  return (
    <div className="container">
      <h1 className='heading'>What is your budget per person?</h1>
      <div className='div-hbut'>
        <Link
          className={`hbut1 ${data.budget === 'Rs.30,000 – Rs.50,000' ? 'active' : ''}`}
          to="#"
          onClick={() => handleButtonClick('Rs.30,000 – Rs.50,000')}
        >
          Rs.30,000 – Rs.50,000
        </Link>
        <Link
          className={`hbut1 ${data.budget === 'Rs.50,000 – Rs.70,000' ? 'active' : ''}`}
          to="#"
          onClick={() => handleButtonClick('Rs.50,000 – Rs.70,000')}
        >
          Rs.50,000 – Rs.70,000
        </Link>
        <Link
          className={`hbut1 ${data.budget === 'Rs.70,000+' ? 'active' : ''}`}
          to="#"
          onClick={() => handleButtonClick('Rs.70,000+')}
        >
          Rs.70,000+
        </Link>
      </div>

      <Link to="#" onClick={() => clickPrev(laststep)} className='backward'>
        <img src={back} alt="logo" className='back-btn' />
      </Link>
      <div style={{ padding: '20px' }}>
        <span className='pro-heading'>Progress</span>
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `20%` }}></div>
        </div>
      </div>
    </div>
  );
}

export default IndiaBudget