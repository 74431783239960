import React, { useState } from 'react';
// import "./TravelWith.css";
import { FiMinusCircle } from "react-icons/fi";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from 'react-router-dom';
import back from '../../assest/left-arrow.png';


const TravelWith = ({data, setFormData, laststep, setLastStep, changeStep}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [activeButton, setActiveButton] = useState('');


  const handleButtonClick = (type) => {
    setActiveButton(type);
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      travelwith: type, // Update the name field
    }));
   
   
    const showOptionsState = type === 'MY FAMILY' || type === 'MY FRIENDS';
    if(!showOptionsState){
      setLastStep(8);
      changeStep(9);
    }
    setShowOptions(showOptionsState);
  };

  const handleIncrement = (type) => {
    if (type === 'adults') setAdults(adults + 1);

    if (type === 'children') setChildren(children + 1);
  };

  const handleDecrement = (type) => {
    if (type === 'adults' && adults > 1) setAdults(adults - 1);
    if (type === 'children' && children > 0) setChildren(children - 1);
  };


  const handleNext = () => {
    setLastStep(8);
    changeStep(9);
  }

  const showNextButton = (activeButton === 'MY FAMILY' || activeButton === 'MY FRIENDS') && showOptions;
  const clickPrev = (step) => {
    setLastStep(step-1);
    changeStep(step);
  }


  return (
    <div className='container'>
        <h1 className='heading'>Who will you be travelling with?</h1>
        <div className='div-hbut'>
        
            <Link 
              to="#"
              className={`hbut1 ${data.travelwith === 'I TRAVEL SOLO' ? 'active' : ''}`} 
              onClick={() => handleButtonClick('I TRAVEL SOLO')}
            >
              I TRAVEL SOLO
            </Link>
            <Link 
              to="#"
              className={`hbut1 ${data.travelwith === 'MY PARTNER' ? 'active' : ''}`} 
              onClick={() => handleButtonClick('MY PARTNER')}
            >
              MY PARTNER
            </Link>
            <button 
              className={`hbut1 ${data.travelwith === 'MY FAMILY' ? 'active' : ''}`} 
              onClick={() => handleButtonClick('MY FAMILY')}
            >
              MY FAMILY
            </button>
            <button 
              className={`hbut1 ${data.travelwith === 'MY FRIENDS' ? 'active' : ''}`} 
              onClick={() => handleButtonClick('MY FRIENDS')}
            >
              MY FRIENDS
            </button>
        </div>
        <div className={`selectoption ${showOptions ? 'show' : 'hide'}`}>
            <div className='selectopt1'>
                <h3 className='optiontext1'>ADULTS (19+ YEARS)</h3>
                <FiMinusCircle className='minus' onClick={() => handleDecrement('adults')}/>
                <span className='texta'>{adults}</span>
                <FiPlusCircle className='plus' onClick={() => handleIncrement('adults')}/>
            </div>
            <div className='selectopt2'>
                <h3 className='optiontext1'>CHILDREN (5-18 YEARS)</h3>
                <FiMinusCircle className='minus' onClick={() => handleDecrement('children')}/>
                <span className='textc'>{children}</span>
                <FiPlusCircle className='plus' onClick={() => handleIncrement('children')}/>
                <p className='textc2'>Asilia camps welcome families with children from the age of 5 years</p>
            </div>
        </div>
        {showNextButton && (
            <div><button onClick={handleNext} className='org-btn'>Next</button></div>
        )}
        <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
        <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `70%` }}
              ></div>
            </div>
          </div>
    </div>
  );
}

export default TravelWith;
