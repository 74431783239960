import React from 'react';
import { Link} from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import './Form.css';
import { CiLock } from "react-icons/ci";
import back from '../../assest/left-arrow.png';  

// Fetch phone codes dynamically from a public API
const getPhoneCode = async (countryCode) => {
  try {
    const response = await fetch(`https://restcountries.com/v3.1/all`);
    const countries = await response.json();
    const country = countries.find(c => c.cca2 === countryCode || c.cca3 === countryCode);
    return country && country.idd && country.idd.root + country.idd.suffixes[0];
  } catch (error) {
    console.error('Error fetching phone code:', error);
    return '';
  }
};

// Enhance country data to include phone codes
const getCountriesWithCodes = async () => {
  const countryData = countryList().getData();
  const countriesWithCodes = await Promise.all(
    countryData.map(async (country) => ({
      ...country,
      phoneCode: await getPhoneCode(country.value)
    }))
  );
  return countriesWithCodes;
};

const CountrySelector = React.memo(({ value, onChange }) => {
  const [options, setOptions] = React.useState([]);
  
  React.useEffect(() => {
    const fetchOptions = async () => {
      try {
        const countries = await getCountriesWithCodes();
        setOptions(countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchOptions();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <Select 
      options={options} 
      value={value} 
      onChange={onChange} 
      placeholder="Select a country"
      classNamePrefix="CountrySelector"
    />
  );
});

const UserForm = ({ data, laststep, setLastStep, changeStep }) => {
  const [country, setCountry] = React.useState(null);
  const [phoneNumberCode, setPhoneNumberCode] = React.useState('');
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    const initializeCountry = async () => {
      try {
        const countries = await getCountriesWithCodes();
        const india = countries.find(c => c.value === 'IN'); // Find India
        if (india) {
          setCountry(india); // Set default country to India
          setPhoneNumberCode(india.phoneCode || '+91'); // Set default phone code
        }
      } catch (error) {
        console.error('Error initializing country:', error);
      }
    };
    initializeCountry();
  }, []); // Empty dependency array ensures this runs only once

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
    setPhoneNumberCode(selectedOption?.phoneCode || '');
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const clickPrev = (step) => {
    setLastStep(step - 1);
    changeStep(step);
  };

  return (
    <div className='containerForm'>
      <h3 className='formheading'>Where can we send your trip suggestions?</h3>
      <form action='https://app.thegamedrive.com/submit.php' method='post'>
        <div className='content'>
          <input type="hidden" value={JSON.stringify(data)} name='data' />
          
          {/* First Name */}
          <div>
            <label htmlFor="firstName">First Name*</label>
            <input 
              type="text" 
              id="firstName" 
              name="firstName" 
              className='f-input'
              required 
            />
          </div>

          {/* Last Name */}
          <div>
            <label htmlFor="lastName">Last Name*</label>
            <input type="text" id="lastName" name="lastName" className='f-input' required />
          </div>

          {/* Email */}
          <div>
            <label htmlFor="email">Email*</label>
            <input type="email" id="email" name="email" className='f-input' required />
          </div>

          {/* Country Selector */}
          <div className='coun'>
            <label>Country of residence *</label>
            <CountrySelector 
              value={country} 
              onChange={handleCountryChange} 
              className='country-select '
            />
            {/* Hidden input to send country name */}
            <input type="hidden" name="country" value={country ? country.label : ''} />
          </div>

          {/* Phone Number */}
          <div>
            <label htmlFor="phoneNumber">Phone Number*</label>
            <input 
              type="tel" 
              id="phoneNumber" 
              name="phoneNumber" 
              className='f-input'
              placeholder={`${phoneNumberCode} `}
              required 
            />
          </div>

          {/* Checkbox for newsletter */}
          <div className='checkbox-section'>
            <input 
              type="checkbox" 
              id="terms" 
              name="terms" 
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span htmlFor="terms" className='terms'>
            Yes, send me The Game Drive’s  newsletter. You can unsubscribe at any time.<br></br> 
            See our <a href="https://thegamedrive.com/terms-and-conditions/" style={{color:'black'}}>Privacy Policy</a>
            </span>
            {/* Hidden input to send checkbox value */}
            <input type="hidden" name="newsletterConsent" value={isChecked ? 'true' : 'false'} />
          </div>
          
          <button type="submit" className='f-btn'>Submit</button>
        </div>
      </form>
   
      {/* Information Section */}
      <div className='info-section'>
        <div className='line'></div>
        <div className='infoeee'>
          <CiLock />
          <p>We protect your personal information. By submitting, you agree to the use of it as described in our Privacy Policy and Notice at Collection. You may opt out of our communications at any time.</p>
        </div>
      </div>

      {/* Back Button */}
      <Link to="#" onClick={() => clickPrev(laststep)} className='backward'>
        <img src={back} alt="logo" className='back-btn' />
      </Link>

      {/* Progress Bar */}
      <div style={{ padding: '20px' }}>
        <span className='pro-heading'>Progress</span>
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `90%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
