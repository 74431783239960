import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import useNavigate
import back from '../../src/assest/left-arrow.png';

function WhichDrive({ data, setFormData, laststep, setLastStep, changeStep }) {

    const [activeButton, setActiveButton] = useState(null);

    const clickNext = (buttonLabel, step) => {
      setActiveButton(buttonLabel);
      setFormData((prevState) => ({
        ...prevState, // Spread the previous state to retain other properties
        drive: buttonLabel, // Update the drive field
        
        
      }));
      
      setLastStep(2);
      changeStep(step);
    };

    // const clickPrev = (step) => {
    //     setLastStep(step - 1);
    //     changeStep(step);
    // }

    return (
        <div className="container">
          <h1 className="heading">Got 2min? Let’s plan your travel (game drive). wjhedkhd</h1>
          <h2 className="heading" style={{'fontSize':'20px'}}>Which Drive would you like?</h2>
          
          <div className="div-hbut">
            <Link 
              to="#" 
              className={`hbut1 ${data.main === 'Indian Jungles' ? 'active' : ''}`}
              onClick={() => clickNext('Indian Jungles', 4)}
            >Indian Jungles</Link>
            <Link 
              to="#" 
              className={`hbut1 ${data.main === 'African Savanas' ? 'active' : ''}`}
              onClick={() => clickNext('African Savanas', 3)}
            >African Savanas</Link>
          </div>

          <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `10%` }}
              ></div>
            </div>
          </div>

          {/* <Link to="#" onClick={() => clickPrev(laststep)} className='backward'>
            <img src={back} alt="logo" className='back-btn' />
          </Link> */}
        </div>
    );
}

export default WhichDrive;
