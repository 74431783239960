import React, { useState } from 'react';
import { Link} from 'react-router-dom'; // Use useNavigate for programmatic navigation
// import "./WhichMonth.css";
import back from '../../assest/left-arrow.png';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm } from '../../Store/Action/formActions';

const WhichMonth = ({data, setFormData, laststep,  setLastStep, changeStep}) => {
  const [activeButton, setActiveButton] = useState(null);
  const [showComponent, setShowComponent] = useState(true);


  const handleButtonClick = (month) => {
    setActiveButton(month);
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      Selectmonth: month, // Update the name field
    }));
    setLastStep(6);
    changeStep(7);
  };
  const clickPrev = (step) => {
    setLastStep(step-1);
    changeStep(4);
  }
  // if (!showComponent) {
  //   return null; // Render nothing if showComponent is false
  // }

  return (
    <div className='container'>
      <h1 className='heading'>Which month?</h1>
      <div className='whichbutton'>
        {["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"].map((month, index) => (
          <a
            key={index}
            className={`wbut1 ${data.Selectmonth === month ? 'active' : ''}`}
            onClick={() => handleButtonClick(month)}
          >
            
            {month}
          </a>
        

        ))}

      </div>
       <Link to="#" onClick={() => clickPrev(laststep)} className='backward'><img src={back} alt="logo" className='back-btn' /></Link>
      <div style={{ padding: '20px' }}>
            <span className='pro-heading'>Progress</span>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `50%` }}
              ></div>
            </div>
          </div>
    </div>
  );
}

export default WhichMonth;
